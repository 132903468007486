@charset "UTF-8";
/**
 * Enhanced
 * Bootstrap the rest of the styles on cut the mustard
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Style mixins
 *
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
h1 {
  font-size: 2rem;
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
}

h3 {
  font-size: 1.25rem;
  font-weight: 700;
}

h6, h5, h4 {
  font-size: 1.125rem;
  font-weight: 700;
}

/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Nature
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Global
 * Main brand settings
 */
h1 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 2rem;
  font-size: min(max(1.5rem, 4vw), 2rem);
  letter-spacing: -0.0390625rem;
  letter-spacing: min(max(-0.011715625rem, 4vw), -0.0390625rem);
  line-height: 2.25rem;
  line-height: min(max(1.6rem, 4vw), 2.25rem);
}

h2 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3.5vw), 1.5rem);
  letter-spacing: -0.011715625rem;
  line-height: 1.6rem;
  line-height: min(max(1.4rem, 3.5vw), 1.6rem);
}

h3 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 3.0vw), 1.25rem);
  letter-spacing: -0.011715625rem;
  line-height: 1.4rem;
}

h6, h5, h4 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2.5vw), 1.125rem);
  letter-spacing: -0.011715625rem;
  line-height: 1.4rem;
}

/**
 * Forms
 * Default form styles
 */
fieldset {
  border: 0;
}

input:focus,
button:focus,
select:focus {
  outline: 3px solid #fece3e;
  will-change: transform;
}

button:active {
  outline: 0;
}

input + label {
  padding-left: 0.5em;
}

/*
 * Layout
 * Universal layout styles
 */
html {
  height: 100%;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.76;
  color: #222222;
  background: #eeeeee;
}

figure {
  margin: 0;
}

abbr[title] {
  text-decoration: none;
}

[contenteditable]:focus,
[tabindex="0"]:focus {
  outline: 3px solid #fece3e;
  will-change: transform;
}

/**
 * Links
 * Basic link styles
 */
a {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  vertical-align: baseline;
  color: #006699;
}
a:focus {
  outline: 3px solid #fece3e;
  will-change: transform;
}

a:active {
  outline: 0;
}

/**
 * Lists
 * Default list styles
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

/**
 * Tables
 * Default table styles
 */
table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

th,
td {
  padding: 4px 7px;
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
  text-align: left;
  line-height: 1.15;
}

th {
  vertical-align: top;
}

td {
  vertical-align: middle;
}

th[valign=top] {
  vertical-align: top;
}

th[valign=middle] {
  vertical-align: middle;
}

thead tr {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
}

tbody tr {
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
}

tbody tr:first-child {
  border-top: 0;
}

th {
  background: #eeeeee;
}

tfoot tr {
  border-top: 2px solid rgb(212.5, 212.5, 212.5);
}

tfoot td {
  font-weight: bold;
}

/**
 * Typography
 * Set base font size
 */
html {
  font-size: 100%;
}

body {
  font-size: 1.125rem;
}

/**
 * Typographic
 * Standard text and heading formatting
 */
/*
 * Default spacing
 * default margin 28px to match line height
 * this is used for mainting vertical rhythm on the page
 */
/*
 * Headings
 */
p:empty {
  display: none;
}

@media print {
  @font-face {
    font-family: "npg-stix-regular";
    src: url("/static/fonts/npg-stix-regular.woff2") format("woff2"), url("/static/fonts/npg-stix-regular.woff") format("woff");
    font-display: swap;
  }
  .stix {
    font-family: "npg-stix-regular", serif;
  }
  body {
    padding: 14px 0 0;
    font-family: serif;
  }
  h1,
  h2,
  h3 {
    font-weight: bold;
    line-height: 1.4;
  }
  h4 {
    font-size: 0.625em;
  }
  h1,
  h2,
  h3,
  p,
  ul {
    margin-bottom: 28px;
  }
  .u-hide-print,
  .optanon-alert-box-wrapper,
  #uptodate-client {
    display: none !important;
  }
  .pin-right {
    display: none;
  }
  .c-article-section__title {
    border-bottom: 5px solid #eeeeee;
    padding-bottom: 0.25em;
  }
  .c-article-section__content {
    margin-bottom: 56px;
  }
  .c-article-main-column {
    width: 100%;
  }
  .c-author-list,
  .c-article-author-affiliation__authors-list,
  .c-article-further-reading__authors-list,
  .c-article-subject-list {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    list-style: none;
    padding: 0;
    font-size: 0.563em;
  }
  .c-author-list__item,
  .c-article-author-affiliation__authors-item,
  .c-article-further-reading__authors-list > li {
    display: inline;
  }
  .c-author-list__show-more,
  .c-author-list__show-less {
    display: none;
  }
  .c-article-author-affiliation__address {
    font-weight: normal;
    font-size: 0.625em;
  }
  .c-article-section__figure,
  .c-article-table {
    border: 5px solid #eeeeee;
    padding: 10px;
    margin-bottom: 28px;
  }
  .c-article-section__figure-caption {
    display: block;
    margin-bottom: 14px;
  }
  .c-article-section__figure-description {
    margin-top: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  .c-article-references__counter {
    font-weight: bold;
    float: left;
    padding: 0 0.3em 0 0;
  }
  .c-article-further-reading__title,
  .c-article-supplementary__title {
    margin-bottom: 0;
  }
  .c-article-license__link {
    float: left;
    margin: 0;
    padding: 0.75em 0.5em 0 0;
  }
  .c-bibliographic-information__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .c-bibliographic-information__item {
    padding: 0 2em 0 0;
  }
  .c-article__sub-heading,
  .c-article-subject-list {
    display: inline-block;
    padding-right: 0.1875rem;
  }
  .c-article-subject-list > li::before {
    content: "•";
    padding: 0 0.25em;
  }
  .c-article-subject-list > li:first-child::before {
    content: "";
    padding: 0;
  }
  .c-article-subject-list > li {
    display: inline;
  }
  .c-article-identifiers {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 0.563em;
  }
  .c-bibliographic-information__value {
    margin-left: 5px;
    margin-right: 10px;
    font-weight: normal;
  }
  .c-bibliographic-information__list-item p {
    font-weight: bold;
  }
  #article-comments-section {
    display: none;
  }
  .c-article-references__download {
    display: none;
  }
  .u-icon {
    width: 1em;
    height: 1em;
  }
}