@import '../../../node_modules/@springernature/brand-context/nature/scss/enhanced';

@media print {

	// Web fonts
	@font-face {
		font-family: 'npg-stix-regular';
		src: url('/static/fonts/npg-stix-regular.woff2') format('woff2'), url('/static/fonts/npg-stix-regular.woff') format('woff');
		font-display: swap;
	}

	.stix {
		font-family: 'npg-stix-regular', serif;
	}

	body {
		padding: 14px 0 0;
		font-family: $context--font-family-serif;
	}

	h1,
	h2,
	h3 {
		font-weight: bold;
		line-height: 1.4;
	}

	h4 {
		font-size: .625em;
	}

	h1,
	h2,
	h3,
	p,
	ul {
		margin-bottom: 28px;
	}

	.u-hide-print,
	.optanon-alert-box-wrapper,
	#uptodate-client {
		display: none !important;
	}

	.pin-right {
		display: none;
	}

	.c-article-section__title {
		border-bottom: 5px solid greyscale(80);
		padding-bottom: 0.25em;
	}

	.c-article-section__content {
		margin-bottom: 56px;
	}

	.c-article-main-column {
		width: 100%;
	}

	.c-author-list,
	.c-article-author-affiliation__authors-list,
	.c-article-further-reading__authors-list,
	.c-article-subject-list {
		font-family: $context--font-family-sans;
		list-style: none;
		padding: 0;
		font-size: .563em;
	}

	.c-author-list__item,
	.c-article-author-affiliation__authors-item,
	.c-article-further-reading__authors-list > li {
		display: inline;
	}

	.c-author-list__show-more,
	.c-author-list__show-less {
		display: none;
	}

	.c-article-author-affiliation__address {
		font-weight: normal;
		font-size: .625em;
	}

	.c-article-section__figure,
	.c-article-table {
		border: 5px solid greyscale(80);
		padding: 10px;
		margin-bottom: 28px;
	}

	.c-article-section__figure-caption {
		display: block;
		margin-bottom: 14px;
	}

	.c-article-section__figure-description {
		margin-top: 14px;
		font-family: $context--font-family-sans;
	}

	.c-article-references__counter {
		font-weight: bold;
		float: left;
		padding: 0 0.3em 0 0;
	}

	.c-article-further-reading__title,
	.c-article-supplementary__title {
		margin-bottom: 0;
	}

	.c-article-license__link {
		float: left;
		margin: 0;
		padding: 0.75em 0.5em 0 0;
	}

	.c-bibliographic-information__list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
	}

	.c-bibliographic-information__item {
		padding: 0 2em 0 0;
	}

	.c-article__sub-heading,
	.c-article-subject-list {
		display: inline-block;
		padding-right: 0.1875rem;
	}

	.c-article-subject-list > li::before {
		content: '\2022';
		padding: 0 0.25em;
	}

	.c-article-subject-list > li:first-child::before {
		content: '';
		padding: 0;
	}

	.c-article-subject-list > li {
		display: inline;
	}

	.c-article-identifiers {
		font-family: $context--font-family-sans;
		font-size: .563em;
	}

	.c-bibliographic-information__value {
		margin-left: 5px;
		margin-right: 10px;
		font-weight: normal;
	}

	.c-bibliographic-information__list-item p {
		font-weight: bold;
	}

	#article-comments-section {
		display: none;
	}

	.c-article-references__download {
		display: none;
	}

	.u-icon {
		width: 1em;
		height: 1em;
	}
}
